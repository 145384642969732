import React, { useState } from "react"
import { H4, P } from "../../common/typography"
import styled from "styled-components"
import { ThemeColor } from "../../../utils/colors"
import { getFromTheme as theme, styledMap } from "../../../utils/styles"
import Icons from "../../icons"
import {
  LayoutOrientation,
  useLayoutOrientation,
} from "../../../utils/breakpoints"
import { NormalComponents } from "react-markdown/lib/complex-types"
import { SpecialComponents } from "react-markdown/lib/ast-to-react"
import ReactMarkdown from "react-markdown"

export interface LocalizedQuestion {
  id: string
  question: string
  answer: string
  sanitizedQuestion: string
  sanitizedAnswer: string
  category: string
}

export type QuestionsByCategory = { [key: string]: LocalizedQuestion[] }

const FaqCategory = styled.div<{
  color: ThemeColor
  layout: LayoutOrientation
}>`
  background-color: ${(props) => theme(`colors.web.${props.color}.initial`)};
  padding: 5px 10px;
  border-radius: ${theme("borderRadius.tags")}px;

  ${P} {
    color: white;
  }

  &:not(:first-child):not(.searching) {
    margin-top: ${styledMap("layout", {
      horizontal: "84px",
      vertical: "24px",
    })};
  }
`

const QuestionTitle = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;

  svg {
    min-width: 24px;
  }
`

const FaqQuestion = styled.div<{ color: ThemeColor }>`
  align-self: stretch;
  user-select: none;
  margin-top: 10px;
  border-bottom: 1px solid ${theme("colors.web.black.pale")};

  ${QuestionTitle} {
    cursor: pointer;
  }

  ${QuestionTitle}, ${P} {
    padding-bottom: 30px;
  }

  ${P} {
    display: none;
  }

  &.expanded {
    ${P} {
      display: block;
    }

    ${H4} {
      color: ${(props) => theme(`colors.web.${props.color}.initial`)};
    }
  }
`

const FaqContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 850px;
  gap: 20px;

  ${FaqCategory} + ${FaqQuestion} {
    margin-top: 0;
  }
`

const FaqQuestions = ({
  questionsByCategories,
  searchTerm,
}: {
  questionsByCategories: QuestionsByCategory
  searchTerm: string
}) => {
  const colors = ["secondary", "accent", "primary"] as ThemeColor[]
  const categories = Object.keys(questionsByCategories)
  const layout = useLayoutOrientation({ threshold: "medium" })

  const searchIsActive = () => searchTerm.trim().length > 1

  const [expandedQuestions, updateExpandedQuestions] = useState(
    new Set<string>()
  )

  const toggleQuestions = (questionId) => {
    const newSet = new Set(expandedQuestions)
    if (!newSet.has(questionId)) {
      newSet.add(questionId)
    } else {
      newSet.delete(questionId)
    }

    updateExpandedQuestions(newSet)
  }

  return (
    <FaqContent>
      {categories.map((category) => {
        const color = colors[categories.indexOf(category) % 3]
        const applicableQuestions = questionsByCategories[category].filter(
          (item) => {
            if (!searchIsActive()) {
              return true
            } else {
              return (
                item.sanitizedQuestion.includes(searchTerm) ||
                item.sanitizedAnswer.includes(searchTerm)
              )
            }
          }
        )

        return (
          <>
            <FaqCategory
              className={searchIsActive() ? "searching" : ""}
              color={color}
              layout={layout}
            >
              <P small>{category}</P>
            </FaqCategory>

            {applicableQuestions.length > 0 ? (
              applicableQuestions.map((item) => (
                <FaqQuestion
                  className={
                    searchIsActive() || expandedQuestions.has(item.id)
                      ? "expanded"
                      : ""
                  }
                  color={color}
                >
                  <QuestionTitle onClick={() => toggleQuestions(item.id)}>
                    <H4>{item.question}</H4>
                    {expandedQuestions.has(item.id) ? (
                      <Icons.ArrowUp.Medium />
                    ) : (
                      <Icons.ArrowDown.Medium />
                    )}
                  </QuestionTitle>
                  <AnswerBody text={item.answer} />
                </FaqQuestion>
              ))
            ) : (
              <P small color="pale">
                Aucun résultat trouvé
              </P>
            )}
          </>
        )
      })}
    </FaqContent>
  )
}

const AnswerBody = ({ text }: { text: string }) => {
  const markdownSubstitutions = {
    p: P,
  } as Partial<
    Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents
  >

  return <ReactMarkdown children={text} components={markdownSubstitutions} />
}

export default FaqQuestions

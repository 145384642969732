import React, { HTMLAttributes, useState } from "react"
import {
  injectIntl,
  IntlProvider,
  IntlShape,
  useIntl,
} from "gatsby-plugin-intl"
import MissionHero, {
  MissionHeroMessages,
} from "../../components/pages/about/hero"
import Layout from "../../components/site/layout"
import Seo from "../../components/common/seo"
import SignUp from "../../components/blocks/signUp"
import { PageContent, Section } from "../../components/common/layout"
import {
  getLocale,
  getLocalizedProps,
  getPathName,
  messagesForLanguage,
} from "../../utils/localized"
import { graphql } from "gatsby"
import styled from "styled-components"
import { getFromTheme as theme } from "../../utils/styles"
import FaqQuestions, {
  QuestionsByCategory,
} from "../../components/pages/faq/questions"
import useBreakpoints, { useLayoutOrientation } from "../../utils/breakpoints"

const faqMessages: MissionHeroMessages = {
  supertitle: "faq.supertitle",
  title: "faq.title",
}

const SearchInput = styled.input`
  border: 0;
  outline: 0;
  padding: 17px 20px;
  border-radius: 50px;

  font-family: ${theme("font.family.paragraph")};
  font-size: ${theme("font.paragraph.size.p3")};
  color: ${theme("colors.web.black.initial")};

  border: 1px solid ${theme("colors.web.black.pale")};

  &:focus {
    outline: none !important;
  }

  &::placeholder {
    font-family: ${theme("font.family.paragraph")};
    font-size: ${theme("font.paragraph.size.p3")};
    color: ${theme("colors.web.black.pale")};
  }
`

const FaqContent = ({
  data,
}: {
  data: { django: { questions: IQuestion[] } }
}) => {
  const intl = useIntl()
  const questionsByCategories: QuestionsByCategory = {}
  const [searchTerm, setSearchTerm] = useState("")

  data.django.questions.forEach((q) => {
    const [question, answer] = getLocalizedProps(
      q,
      ["question", "answer"],
      intl.locale
    ) as string[]

    const [category] = getLocalizedProps(
      q.category,
      ["title"],
      intl.locale
    ) as string[]

    if (questionsByCategories[category] == null) {
      questionsByCategories[category] = []
    }

    questionsByCategories[category].push({
      id: q.id,
      question,
      answer,
      sanitizedQuestion: question.toLocaleLowerCase(),
      sanitizedAnswer: answer.toLocaleLowerCase(),
      category,
    })
  })

  return (
    <PageContent>
      <Section t-xlg h-m-flush>
        <MissionHero messages={faqMessages} />
      </Section>

      <Section t-lg h-centered>
        <SearchSection
          onChange={(event: any) => setSearchTerm(event.target.value)}
        />
      </Section>

      <Section t-lg h-centered>
        <FaqQuestions
          questionsByCategories={questionsByCategories}
          searchTerm={searchTerm}
        />
      </Section>

      <Section t-xlg h-centered h-m-sm>
        <SignUp />
      </Section>
    </PageContent>
  )
}

const FaqPage = ({
  data,
}: {
  data: { django: { questions: IQuestion[] } }
}) => (
  <Layout>
    <FaqContent data={data} />
  </Layout>
)

const SearchSection = (props: HTMLAttributes<HTMLInputElement>) => {
  const { breakpoints, currentBreakpoint } = useBreakpoints()
  const intl = useIntl()

  return (
    <SearchInput
      placeholder={intl.formatMessage({ id: "faq.search.placeholder" })}
      type="text"
      size={breakpoints[currentBreakpoint] > breakpoints.xsmall ? 50 : 26}
      {...props}
    />
  )
}

export const Head = ({ location }) => {
  const locale = getLocale()
  return (
    <IntlProvider messages={messagesForLanguage(locale)} locale={locale}>
      <Seo path={getPathName()} descriptionId="meta.description" />
    </IntlProvider>
  )
}

interface ICategory {
  titleEn: string
  titleFr: string
}

interface IQuestion {
  id: string
  questionEn: string
  questionFr: string
  answerEn: string
  answerFr: string
  category: ICategory
}

export const query = graphql`
  query {
    django {
      questions {
        id
        questionFr
        questionEn
        answerFr
        answerEn
        category {
          titleFr
          titleEn
        }
      }
    }
  }
`

export default FaqPage
